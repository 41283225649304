import { logout, login } from '@/api/user'
import router from '@/router'
import { getInfo } from '../../api/user'

const user = {
    state: {
        access_token: localStorage.getItem('access_token'),
        merchantId: localStorage.getItem('merchantId'),
        merchantName: localStorage.getItem('merchantName'),
        userName: '',
        isReloadUserInfo: false,
        materialId: localStorage.getItem('materialId'),
        session: JSON.parse(localStorage.getItem('session'))
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, access_token) => {
            state.access_token = access_token
            localStorage.setItem('access_token', access_token)
        },
        SET_MERCHANT_ID: (state, merchantId) => {
            state.merchantId = merchantId
            localStorage.setItem('merchantId', merchantId)
        },
        SET_MATERIALID: (state, id) => {
            state.materialId = id
            localStorage.setItem('materialId', id)
        },
        SET_ISRELOADUSERINFO: (state, data) => {
            state.isReloadUserInfo = data
        },
        SET_MERCHANT_NAME: (state, data) => {
            state.merchantName = data
        },
        SET_USER_NAME: (state, data) => {
            state.userName = data
        },
        SET_SESSION: (state, data) => {
            state.session = data
            localStorage.setItem('session',JSON.stringify(data))
        },
    },
    actions: {
        Login({ commit }, userInfo) {
            const phone = userInfo.phone
            return new Promise((resolve, reject) => {
                login(phone, userInfo.userPwd, userInfo.typeId).then(response => {
                    console.log('response==========,', response)
                    if (response.code === 1003) {
                        commit('SET_ACCESS_TOKEN', response.session.token);
                        commit('SET_SESSION', response.session);
                        resolve(response);
                    }
                }).catch(error => {
                    reject(error.message);
                });
            });
        },
        // 掉接口退出登录
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.access_token).then(() => {
                    commit('SET_ACCESS_TOKEN', '');
                    commit('SET_REFRESH_TOKEN', '');
                    localStorage.clear()
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            });
        },

        // 前端登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_ACCESS_TOKEN', '');
                commit('SET_REFRESH_TOKEN', '');
                localStorage.clear()
                router.replace('/login')
                resolve();
            });
        },
        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo({ "token": state.access_token }).then(data => {
                    if (data) {
                        commit('SET_SESSION', data.session);
                        commit('SET_MERCHANT_ID', data.session.lastLoginMerchantId);
                        commit('SET_MERCHANT_NAME', data.session.lastLoginMerchantName);
                        commit('SET_USER_NAME', data.session.userSessionDto.userName);
                        resolve(data);
                    } else {
                        localStorage.removeItem('access_token');
                        commit('SET_ACCESS_TOKEN', '');
                        resolve();
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}

export default user;
