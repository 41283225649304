<template>
  <div class="home">
    <van-skeleton title :row="6" :loading="loading">
      <div class="top-container">
        <van-search
          shape="round"
          background="#4D6FF0"
          placeholder="请输入搜索关键词"
          @focus="handleLinkToSearch"
        />
        <div class="blue-box"></div>
      </div>
      <van-swipe :autoplay="3000" class="top-swipe">
        <van-swipe-item
          v-for="(item, index) in state.bannerInfoList"
          :key="index"
        >
          <div class="top-swipe-item">
            <img :src="item.imgUrl" alt="" />
          </div>
        </van-swipe-item>
        <template #indicator> </template>
      </van-swipe>
      <div class="category-list">
        <van-grid square :border="false">
          <van-grid-item
            v-for="item in showedCategoryList"
            :key="item.categoryId"
            :text="item.categoryName"
            @click="handleCateClick(item)"
          >
            <template #icon>
              <img class="cate-icon" :src="item.logoUrl" alt="" />
            </template>
          </van-grid-item>
        </van-grid>
        <div class="subtitle" v-if="state.videoInfoList.length > 0">
          实景案例
        </div>
        <div class="Carousel">
          <Carousel
            v-if="state.videoInfoList.length > 0"
            :imgs="state.videoInfoList"
          >
            <template #default="scope">
              <case-item :case-info="scope.item" />
            </template>
          </Carousel>
        </div>
        <div class="subtitle" v-if="state.hotSuitList.length > 0">
          热卖套装
        </div>
        <div class="Carousel">
          <Carousel
            v-if="state.hotSuitList.length > 0"
            :imgs="state.hotSuitList"
          >
            <template #default="scope">
              <commodity-item :commodity-info="scope.item" />
            </template>
          </Carousel>
        </div>
        <div class="subtitle">
          精品推荐
        </div>
        <div class="Carousel">
          <van-list
            v-model:loading="listLoading"
            :finished="finished"
            finished-text=""
            :immediate-check="false"
            @load="onLoad"
            offset="30"
          >
            <!-- <van-grid :column-num="2" :border="false">
              <van-grid-item
                v-for="value in state.recommendGoods"
                :key="value"
                style="background:rgb(250,250,250)"
              >
           
              </van-grid-item>
            </van-grid> -->
            <div class="goods-item-box">
              <div
                class="goods-item"
                v-for="value in state.recommendGoods"
                :key="value"
              >
                <goods-item :good-info="value" />
              </div>
            </div>
          </van-list>
        </div>
        <div class="subtitle" v-if="state.hotMaterialList.length > 0">
          热销爆款
        </div>
        <div class="Carousel" v-if="state.hotMaterialList.length > 0">
          <Carousel :imgs="state.hotMaterialList">
            <template #default="scope">
              <goods-item :good-info="scope.item" headSales />
            </template>
          </Carousel>
        </div>
      </div>
    </van-skeleton>
  </div>
  <BottomTabbar />
</template>
<script setup>
import { reactive, ref, toRefs } from "@vue/reactivity";
import GoodsItem from "@/components/GoodsItem.vue";
import CaseItem from "@/components/CaseItem.vue";
import Carousel from "@/components/Carousel.vue";
import CommodityItem from "@/components/CommodityItem.vue";
import { useRouter } from "vue-router";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { getShopHomeInfo, listShopMaterialByPage } from "../api/common";
import { Toast } from "vant";
import BottomTabbar from "../components/bottomTabbar.vue";

const router = useRouter();
const store = useStore();
const token = computed(() => store.getters.token);
const materialId = computed(() => store.getters.materialId);
const merchantId = computed(() => store.getters.merchantId);
const loading = ref(true);
const finished = ref(false);
const listLoading = ref(false);
const searchParam = reactive({
  token: token.value,
  page: 1,
  pageSize: 50,
  param: { materialStockId: materialId.value },
});

const showedCategoryList = computed(() => {
  if (state.categoryInfoList.length === 0) {
    return [
      {
        categoryId: null,
        categoryName: "床",
        logoUrl: require("@/assets/images/chuang.png"),
      },
      {
        categoryId: null,
        categoryName: "沙发",
        logoUrl: require("@/assets/images/shafa.png"),
      },
      {
        categoryId: null,
        categoryName: "床垫",
        logoUrl: require("@/assets/images/chuangdian.png"),
      },
      {
        categoryId: null,
        categoryName: "餐桌椅",
        logoUrl: require("@/assets/images/yizi.png"),
      },
    ];
  }
  if (state.categoryInfoList.length >= 7) {
    return state.categoryInfoList.slice(0, 7).concat({
      categoryId: "more",
      categoryName: "更多",
      logoUrl: require("@/assets/images/more.png"),
    });
  }
  return state.categoryInfoList;
});

const images = [{}];
const state = reactive({
  bannerInfoList: [],
  categoryInfoList: [],
  videoInfoList: [],
  hotMaterialList: [],
  hotSuitList: [],
  recommendGoods: [],
});
const handleLinkToSearch = () => {
  router.push("/goodsSearch");
};

const handleCateClick = (item) => {
  if (item.categoryId) {
    if (item.categoryId === "more") {
      router.push("/category");
    } else {
      router.push({
        name: "goodsSearch",
        query: { categoryId: item.categoryId, categoryName: item.categoryName },
      });
    }
  }
};
const onLoad = async () => {
  searchParam.page++;
  await loadRecommendGoods();
  listLoading.value = false;
};
const loadRecommendGoods = async () => {
  let res = await listShopMaterialByPage(searchParam);
  if (res.list.length === 0) {
    finished.value = true;
    return;
  }
  state.recommendGoods = [...state.recommendGoods, ...res.list];
};
const loadHomeInfo = async () => {
  loading.value = true;
  Toast.loading({ duration: 300, message: "加载中" });
  let param = {
    token: token.value,
    materialId: materialId.value,
    merchantId: merchantId.value,
  };
  let data = await getShopHomeInfo(param);
  state.bannerInfoList = data.bannerInfoList;
  state.categoryInfoList = data.categoryInfoList;
  state.videoInfoList = data.videoInfoList;
  state.hotMaterialList = data.hotMaterialList;
  state.hotSuitList = data.hotSuitList;

  loading.value = false;
  Toast.clear();
};
loadHomeInfo();
loadRecommendGoods();
</script>

<style lang="scss" scoped>
.top-container {
  background-color: #fff;
  background-color: #4d6ff0;
  position: relative;
  .blue-box {
    height: 60px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: #4d6ff0;
      top: 10px;
      left: 0px;
      height: 100px;
      width: 100%;
      border-radius: 70%;
    }
  }
}
.top-swipe {
  position: relative;
  top: -50px;
  border-radius: 10px;
  margin: 5px 8px;
  .top-swipe-item {
    height: 150px;
    background: transparent;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.category-list {
  position: relative;
  top: -50px;
  .cate-icon {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.subtitle {
  color: #171725;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  background: #fff;
  padding: 24px 12px 12px 12px;
}
.Carousel {
  padding: 0 12px;
  .goods-item-box {
    display: flex;
    flex-wrap: wrap;
    .goods-item {
      margin-bottom: 9px;
    }
    .goods-item:nth-child(2n-1) {
      margin-right: 9px;
    }
  }
}
</style>

<style lang="scss">
.home {
  .van-grid-item__content {
    background: #fff;
  }
  .van-grid-item__text {
    color: #646566;
    font-size: 0.32rem;
    line-height: 1.5;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70px;
  }
}
</style>
